import Vue from "vue"
import axios from "axios"
import router from "@router"
import SearchMixin from "@mixins/search"
import AdminAccess from "@mixins/admin-access"
import { initSentrySession } from "@plugins/sentry"
import { initAmplitude } from "@plugins/amplitude"
import { initStonlySession } from "@plugins/stonly"
import { ShareTokenTypes } from "@mixins/share-token/types"
import StaticHelper from "@mixins/static-helpers"
import {
  ssoOnlyGroupCheck,
  ssoOnlyNetworkCheck,
} from "@mixins/auth/auth-helpers"

export const state = {
  sessionObject: null,
  appInitLoad: false,
  shareToken: null,
  csrfToken: null,
  defaultRoute: null,
  trueActiveGroup: null,
  trueActiveNetwork: null,
  trueActiveGroupMeta: null,
  userConfig: null,
  sessionDefaultBehavior: null,
  sessionShowNotifications: true,
}

export const mutations = {
  SET_CURRENT_SESSION(state, sessionObject) {
    state.sessionObject = sessionObject

    if (_.isEmpty(sessionObject)) {
      state.trueActiveGroup = null
      state.trueActiveNetwork = null
      state.trueActiveGroupMeta = null
      state.userConfig = null
      state.sessionDefaultBehavior = null
      state.sessionShowNotifications = true
      initAmplitude()
    } else {
      const user = _.first(_.get(sessionObject, "users"))
      const group = _.first(_.get(sessionObject, "groups"))
      const network = _.first(_.get(sessionObject, "networks"))

      state.trueActiveGroup = group
      state.trueActiveNetwork = network
      state.trueActiveGroupMeta = _.first(_.get(sessionObject, "group_meta"))
      state.userConfig = _.get(sessionObject, "user_config")
      state.sessionDefaultBehavior = _.get(
        sessionObject,
        "session.default_behavior"
      )
      state.sessionShowNotifications = _.get(
        sessionObject,
        "session.show_notifications"
      )

      initAmplitude({
        id: user.id,
        name: user.name,
        email: user.email,
        network_id: network.id,
        network_name: network.name,
        group_id: group.id,
        group_name: group.name,
      })
    }

    initSentrySession()
    initStonlySession()
  },
  SET_SHARE_SESSION(state, shareToken) {
    state.shareToken = shareToken
  },
  SET_GROUPS(state, data) {
    state.sessionObject.groups = data.results
  },
  SET_USER_PRIVACY_SESSION(state, response) {
    state.sessionObject.users[0].privacy_consent_date =
      response.data.user.privacy_consent_date
  },
  UPDATE_ACTIVE_NETWORK(state, network) {
    const networks = _.get(state, "sessionObject.networks", [])
    const index = _.findIndex(networks, (n) => {
      return n.id === network.id
    })
    Vue.set(state.sessionObject.networks, index, network)
  },
  UPDATE_ACTIVE_GROUP(state, group) {
    const groups = _.get(state, "sessionObject.groups", [])
    const index = _.findIndex(groups, (g) => {
      return g.id === group.id
    })
    Vue.set(state.sessionObject.groups, index, group)
    state.trueActiveGroup = group
  },
  UPDATE_ACTIVE_USER(state, user) {
    if (!StaticHelper.blank(user)) {
      Vue.set(state.sessionObject.users, 0, user)
    }
  },
  SET_APP_INIT_LOADING(state, newValue) {
    state.appInitLoad = newValue
  },
  NULLIFY_SHARE_TOKEN(state) {
    state.shareToken = null
  },
  SET_CSRF_TOKEN(state, newValue) {
    state.csrfToken = newValue
  },
  SET_DEFAULT_ROUTE(state, newValue) {
    state.defaultRoute = newValue
  },
  SET_TRUE_ACTIVE_GROUP_META(state, newValue) {
    state.trueActiveGroupMeta = newValue
  },
  SET_USER_CONFIG_DEFAULTS(state, newValue) {
    Vue.set(state.userConfig.data, "companies_index_defaults", newValue)
  },
  SET_SESSION_DEFAULT_BEHAVIOR(state, newValue) {
    state.sessionDefaultBehavior = newValue
  },
}

export const getters = {
  sessionObject(state) {
    return _.get(state, "sessionObject")
  },
  networks(state) {
    return _.get(state, "sessionObject.networks")
  },
  groups(state) {
    return _.get(state, "sessionObject.groups")
  },
  hasActiveSession(state) {
    return _.get(state, "sessionObject.users[0].active", false)
  },
  activeUser(state) {
    return state.shareToken
      ? { id: state.shareToken.added_by_user_id }
      : _.get(state, "sessionObject.users[0]")
  },
  activeGroup(state) {
    return state.shareToken
      ? _.get(state, "shareToken.shared_by.group")
      : _.get(state, "trueActiveGroup")
  },
  activeNetwork(state) {
    return state.shareToken
      ? _.get(state, "shareToken.shared_by.network")
      : _.get(state, "trueActiveNetwork")
  },
  ssoOnly(state, getters) {
    let ag = getters.activeGroup
    let an = getters.activeNetwork
    let st = getters.shareToken
    if (!ag || !an || st) return false
    let gSso = ssoOnlyGroupCheck(ag)
    let nSso = ssoOnlyNetworkCheck(an)
    if (gSso || nSso) return true
    return false
  },
  shareToken(state) {
    return _.get(state, "shareToken.secret", null)
  },
  shareTokenExportEnabled(state) {
    return (
      _.get(state, "shareToken.option_one") &&
      _.get(state, "shareToken.use_case") === ShareTokenTypes.SHARED_REPORT
    )
  },
  shareSession(state) {
    return _.get(state, "shareToken", null)
  },
  csrfToken(state) {
    return state.csrfToken
  },
  isActiveAdmin(state, getters) {
    return getters.activeGroupAdmin || getters.activeNetworkAdmin
  },
  kiteAdmin(state) {
    return _.get(state, "sessionObject.session.kite_admin") || false
  },
  isNetworkAdmin(state) {
    if (_.get(state, "sessionObject.session.kite_admin")) {
      return true
    }
    let maps = _.get(state, "sessionObject.session.max_admin_policy_set")
    if (_.isEmpty(maps)) return false
    maps = _.compact(
      maps.map((p) => {
        if (p.match(/^n[0-9]+:2$/)) return p
      })
    )
    return !_.isEmpty(maps)
  },
  groupsSessionUserCanAdmin(state) {
    let networks = _.get(state, "sessionObject.session.admin_control.networks")
    if (_.isEmpty(networks)) return []
    let groupIds = []
    for (let i = 0; i < networks.length; i++) {
      if (!networks[i].admin) continue
      for (let j = 0; j < networks[i].groups.length; j++) {
        groupIds.push(networks[i].groups[j].id)
      }
    }
    return groupIds
  },
  activeGroupAdmin(state, getters) {
    return getters.userIsAdminOf({
      id: getters.activeGroup.id,
      namespace: "groups",
    })
  },
  activeNetworkAdmin(state, getters) {
    return getters.userIsAdminOf({
      id: getters.activeNetwork.id,
      namespace: "networks",
    })
  },
  privacyConsentDate(state) {
    return _.get(state, "sessionObject.users[0].privacy_consent_date", false)
  },
  adminControl(state) {
    return _.get(state, "sessionObject.session.admin_control")
  },
  userIsAdminOf: (state) => (options) => {
    // Example of options -> { id: 31, namespace: "networks" }
    // namespace can be networks or groups
    if (_.isEmpty(state.sessionObject)) {
      return false
    } else if (state.sessionObject.session.kite_admin) {
      return true
    } else {
      const ac = state.sessionObject.session.admin_control
      const access = AdminAccess.buildLevelOfAccess(ac)
      const groupAdNetIds = AdminAccess.uniqGroupAdminNetworkIds(access.groups)
      // example of access object ->
      // access = { networks: [{ id: 1, name: "VISA" }], groups: [{ id: 2, name: "Innovation", netId: 1, netName: "Visa" }] }
      // For more information on AdminAccess, visit admin-access.js

      if (access[options.namespace].length === 0 && groupAdNetIds.length < 2) {
        return false
      } else if (groupAdNetIds > 1 && options.id == null) {
        // This block of code will be hit when a user travels to platform settings.
        // PlatformSettings does not have an ID (hence why we check options.id).
        // We need to let users travel between networks if they are a group admin
        // within multiple networks.
        return true
      } else {
        const adminAccess = access[options.namespace].map((x) => x.id)
        return adminAccess.indexOf(options.id) !== -1
      }
    }
  },
  appInitLoad(state) {
    return _.get(state, "appInitLoad")
  },
  activeBrandColor(state, getters) {
    return (
      _.get(getters, "activeGroup.brand_color") ||
      _.get(getters, "activeNetwork.brand_color") ||
      "#1C1836"
    )
  },
  defaultRoute(state) {
    return state.defaultRoute
  },
  defaultBehavior(state) {
    return state.sessionDefaultBehavior || {}
  },
  showNotifications(state) {
    return state.sessionShowNotifications
  },
  activeIntegrations(state, getters) {
    const connections = state.shareToken
      ? _.get(
          state.shareToken,
          "shared_by.group.group_meta.connection_settings"
        )
      : _.get(getters.sessionObject, "group_meta[0].connection_settings")

    return {
      // Providers
      clearbit: _.get(connections, "provider.clearbit_enrichment", false),
      crunchbase: _.get(connections, "provider.crunchbase_enrichment", false),
      similarweb: _.get(connections, "provider.similar_web_enrichment", false),
      pitchbook: _.get(connections, "provider.pitchbook_enrichment", false),
      owler: _.get(connections, "provider.owler_enrichment", false),
      owlernews: _.get(connections, "provider.owler_news", false),
      twitter: _.get(connections, "provider.twitter_enrichment", false),
      // Integrations
      typeform: _.get(connections, "integration.typeform", false),
      flatfile: _.get(connections, "integration.flatfile", false),
      pigeonhole: _.get(connections, "integration.pigeonhole", false),
      youtube: _.get(connections, "integration.youtube", false),
      salesforce: _.get(connections, "integration.salesforce", false),
      microsoft_dynamics_365: _.get(
        connections,
        "integration.microsoft_dynamics_365",
        false
      ),
      affinity: _.get(connections, "integration.affinity", false),
    }
  },
  activeGroupMeta(state) {
    return _.get(state, "trueActiveGroupMeta")
  },
  userConfig(state) {
    return _.get(state, "userConfig")
  },
  activeGroupUserConfig(state, getters) {
    const id = _.get(getters.activeGroup, "id")
    const defaults = _.get(getters.userConfig, "data.companies_index_defaults")
    return _.get(defaults, id, "group_user_companies_index_cache")
  },
}

export const actions = {
  signIn({ commit, dispatch }, { username, password, code } = {}) {
    // Conditionally create the payload to ensure ONLY the correct values are added
    const signinPayload = {}
    if (username) {
      signinPayload.username = username
    }
    if (password) {
      signinPayload.password = password
    }
    if (code) {
      signinPayload.code = code
    }

    // Send the payload to the server
    return new Promise((resolve, reject) => {
      dispatch("getCsrfToken").then(() => {
        axios
          .post("session", signinPayload)
          .then((response) => {
            commit("SET_CURRENT_SESSION", response.data)
            dispatch("app/initAppCaches", null, { root: true })
            resolve(response.data)
          })
          .catch((e) => {
            commit("SET_CURRENT_SESSION", null)
            reject(e)
          })
      })
    })
  },
  signOut({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch("analytics/processQueue", null, { root: true })
      axios
        .delete("session", {})
        .then(() => {
          window.localStorage.clear()
          window.location = "/"
          resolve()
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },
  passwordReset({ commit, dispatch }, email) {
    return new Promise((resolve, reject) => {
      if (_.isEmpty(email)) {
        return reject()
      }

      dispatch("getCsrfToken").then(() => {
        axios
          .post("/snowflakes/timed_actions/request_password_reset", {
            email: email,
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            console.error(error)
            reject(error)
          })
      })
    })
  },
  destroyCurrentSession({ commit }) {
    commit("SET_CURRENT_SESSION", null)
  },
  validate({ commit, state, dispatch }, params) {
    if (
      _.get(state, "sessionObject.users[0].active", false) &&
      !_.get(params, "reload", false)
    ) {
      return state.sessionObject
    }

    return new Promise((resolve, reject) => {
      commit("SET_APP_INIT_LOADING", true)
      return axios
        .get("session")
        .then((response) => {
          commit("SET_CURRENT_SESSION", response.data)
          commit("SET_APP_INIT_LOADING", false)
          dispatch("loadGroups")
          dispatch("app/initAppCaches", null, { root: true })
          resolve(response.data)
        })
        .catch((error) => {
          commit("NULLIFY_SHARE_TOKEN")
          commit("SET_CURRENT_SESSION", null)
          commit("SET_APP_INIT_LOADING", false)
          reject(error.response)
        })
    })
  },
  validateShareToken({ commit, state, dispatch }, shareToken) {
    if (_.get(state, "shareToken", null)) {
      return true
    }
    commit("SET_APP_INIT_LOADING", true)
    return axios
      .get(`share_tokens/verify/${shareToken}`)
      .then((response) => {
        commit("SET_SHARE_SESSION", response.data.share_token)
        commit("SET_APP_INIT_LOADING", false)
        return true
      })
      .catch((e) => {
        commit("NULLIFY_SHARE_TOKEN")
        commit("SET_APP_INIT_LOADING", false)
        return e
      })
  },
  switchApps({ dispatch, commit, state, getters }, payload) {
    if (state.sessionObject) {
      return new Promise((resolve, reject) => {
        axios
          .put("session", { active_app: _.get(payload, "active_app") })
          .then((response) => {
            commit("SET_CURRENT_SESSION", response.data)

            dispatch("getDefaultRoute").then((getDefaultRoute) => {
              router.push(getDefaultRoute).catch((e) => {})
              resolve(response.data)
            })
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  },
  validateActiveBrowserTabSession({ commit, dispatch, getters }) {
    // Verify the session state and caches are correct by comparing the cached activeGroup
    // against the Session activeGroup. If they differ, the currentRoute is reloaded.
    return new Promise((resolve, reject) => {
      commit("SET_APP_INIT_LOADING", true)

      return axios
        .get("session")
        .then((response) => {
          // Authenticated - if group or user differs, show popup modal warning of reload.
          const currentGroupId = _.get(response.data, "groups[0].id")
          const cachedGroupId = _.get(getters, "activeGroup.id")
          const currentUserId = _.get(response.data, "users[0].id")
          const cachedUserId = _.get(getters, "activeUser.id")

          if (
            !_.isEqual(currentGroupId, cachedGroupId) ||
            !_.isEqual(currentUserId, cachedUserId)
          ) {
            dispatch(
              "app/setActiveDialog",
              {
                dialog: "activeModal",
                context: "AppSessionReloadWarningModal",
                title: "Group Change Detected",
                meta: {
                  action: "change",
                },
              },
              { root: true }
            )
          }

          // Refresh notification stats for the active tab
          dispatch("notifications/fetchNotificationStats", null, { root: true })

          commit("SET_APP_INIT_LOADING", false)
          resolve()
        })
        .catch((error) => {
          // Unauthenticated - show popup modal warning of reload.
          dispatch(
            "app/setActiveDialog",
            {
              dialog: "activeModal",
              context: "AppSessionReloadWarningModal",
              title: "Signout Detected",
              meta: {
                action: "logout",
              },
            },
            { root: true }
          )
          reject(error.response)
        })
    })
  },
  switchGroups({ dispatch, commit, state }, params) {
    if (state.sessionObject) {
      dispatch("analytics/processQueue", null, { root: true })
      return new Promise((resolve, reject) => {
        axios
          .put("session", { group_id: _.get(params, "groupId") })
          .then((response) => {
            window.location = "/"
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  },
  setDefaultBehavior({ getters, commit }, params) {
    if (getters.sessionObject) {
      // 0 = disabled, 1 = active
      let newVal = !_.isEmpty(getters.defaultBehavior)
        ? _.cloneDeep(getters.defaultBehavior)
        : {
            app_sidebar: 1,
            container_grid: 1,
            company_drawer: 1,
          }

      if (_.isNumber(_.get(params, "app_sidebar"))) {
        newVal = _.set(newVal, "app_sidebar", params.app_sidebar)
      }

      if (_.isNumber(_.get(params, "container_grid"))) {
        newVal = _.set(newVal, "container_grid", params.container_grid)
      }

      if (_.isNumber(_.get(params, "company_drawer"))) {
        newVal = _.set(newVal, "company_drawer", params.company_drawer)
      }

      // Update cached values to immediately reflect the user's new default.
      commit("SET_SESSION_DEFAULT_BEHAVIOR", newVal)

      // Save the user's new default to their session object.
      return new Promise((resolve, reject) => {
        axios
          .put("session", { default_behavior: newVal })
          .then((response) => {
            resolve(response.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  },
  loadGroups({ dispatch, commit, getters }) {
    const q = SearchMixin.buildSearchObject({
      indices: ["groups"],
      includes: SearchMixin.groupIncludes,
      customFilters: [
        {
          filter: "by_groups_user_is_member",
          attr: "id",
          user_id: _.get(getters, "activeUser.id"),
        },
      ],
    })

    return axios
      .get("search", {
        params: { q: q },
      })
      .then((response) => {
        commit("SET_GROUPS", response.data)
      })
      .catch((e) => {
        console.error(e)
      })
  },
  async updatePrivacyConsent({ commit }, obj) {
    await axios
      .put(`users/${obj.id}`, obj)
      .then((response) => {
        commit("SET_USER_PRIVACY_SESSION", response)
        return Promise.resolve(null)
      })
      .catch((error) => {
        this.error = error
      })
  },
  updateActiveNetwork({ commit }, network) {
    commit("UPDATE_ACTIVE_NETWORK", network)
  },
  updateActiveGroup({ commit }, group) {
    commit("UPDATE_ACTIVE_GROUP", group)
  },
  updateActiveUser({ commit }, user) {
    commit("UPDATE_ACTIVE_USER", user)
  },
  nullifyShareToken({ commit }) {
    commit("NULLIFY_SHARE_TOKEN")
  },
  getCsrfToken({ commit }) {
    return new Promise((resolve) => {
      axios
        .get("snowflakes/csrf")
        .then((response) => {
          commit("SET_CSRF_TOKEN", _.get(response, "data.token", null))
          resolve(_.get(response, "data.token", null))
        })
        .catch((error) => {
          commit("SET_CSRF_TOKEN", null)
          commit("SET_CURRENT_SESSION", null)
          resolve()
        })
    })
  },
  getDefaultRoute({ commit, dispatch, getters }) {
    return new Promise((resolve) => {
      // ===
      // Return if shareToken
      // ===
      if (getters["shareToken"]) {
        return resolve()
      }

      // ===
      // Return cache
      // ===
      if (!_.isEmpty(getters.defaultRoute)) {
        return resolve(getters.defaultRoute)
      }
      // ===
      // Find and set cache
      // ===
      else if (getters.sessionObject) {
        dispatch(
          "contextViews/getRouteByPermalinkFor",
          {
            parent_type: "Group",
            parent_id: _.get(getters, "activeGroup.id"),
            permalink: _.get(
              getters,
              "sessionObject.session.default_context_permalink"
            ),
            reset_cache: true,
          },
          { root: true }
        ).then((defaultRoute) => {
          commit("SET_DEFAULT_ROUTE", defaultRoute)
          resolve(defaultRoute)
        })
      }
    })
  },
  setActiveGroupMeta({ commit }, groupMeta) {
    commit("SET_TRUE_ACTIVE_GROUP_META", groupMeta)
  },
  setDefaultUserConfiguration({ commit, getters }, config) {
    const id = _.get(getters.activeGroup, "id")
    let userConfig = _.cloneDeep(getters.userConfig)
    userConfig.data.companies_index_defaults[id] = config

    return new Promise((resolve, reject) => {
      axios
        .put(`user_configs/${userConfig.id}`, { user_config: userConfig })
        .then((response) => {
          commit(
            "SET_USER_CONFIG_DEFAULTS",
            _.get(response, "data.user_config.data.companies_index_defaults")
          )
          resolve(response.data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}
